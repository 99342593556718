<template>
	<div>
		<!-- 顶部NavMenu -->
		<NavMenu class="NavMenu"></NavMenu>
		<!-- 中部内容盒子 -->
		<div class="content">
			<router-view> </router-view>
		</div>
		<!-- 底部bottomBar -->
		<bottomBar v-if="$store.state.gameVersion == 4"></bottomBar>
		<div class="recordNumberBox" v-if="$store.state.gameVersion == 4">
			<!-- 备案号 -->
			<a href="https://beian.miit.gov.cn" class="text paddingTop2">
				版权所有闽ICP备2023016110号-1
			</a>
		</div>
		<div v-if="bgFn()" class="bg"></div>
	</div>
</template>

<script>
	// import API from "@/api/index.js";
	import NavMenu from "@/components/NavMenu/index.vue";
	import bottomBar from "@/components/bottomBar/index.vue";
	export default {
		components: {
			NavMenu,
			bottomBar
		},
		data() {
			return {
				//当前路由
				currentRoute: "",
			};
		},

		//计算属性
		computed: {},

		//监控data中的数据变化
		watch: {
			//监听路由地址
			$route(to) {
				this.currentRoute = to.path;
			},
		},

		//生命周期 - 创建完成（可以访问当前this实例）
		created() {
			//监听路由地址
			this.currentRoute = this.$route.path;
		},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {},
		//生命周期 - 创建之前
		beforeCreate() {},
		//生命周期 - 挂载之前
		beforeMount() {},
		//生命周期 - 更新之前
		beforeUpdate() {},
		//生命周期 - 更新之后
		updated() {},
		//生命周期 - 销毁之前
		beforeDestroy() {},
		//生命周期 - 销毁完成
		destroyed() {},
		//如果页面有keep-alive缓存功能，这个函数会触发
		activated() {},

		//方法
		methods: {
			bgFn() {
				// this.currentRoute==/reception/revisionRecord或者/reception/cardGuide ==true
				if (
					this.currentRoute == "/reception/revisionRecord" ||
					this.currentRoute == "/reception/cardGuide" ||
					this.currentRoute == "/reception/addDeck"
				) {
					return true;
				} else {
					return false;
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	.recordNumberBox {
		position: fixed;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 100;
		.text {
			font-size: 16px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: bold;
			color: #000;
			line-height: 16px;opacity: .6;
		}
	}

	.content {
		width: 100%;
	}

	.NavMenu {
		width: 100%;
		//绝对定位
		position: absolute;
		z-index: 2;
		top: 0;
	}

	.bg {
		width: 100%;
		height: 100%;
		background-image: url("@/assets/reception/20230628144745.png");
		background-size: 100% 100%;
		position: fixed;
		top: 0;
		z-index: -1;
	}
</style>