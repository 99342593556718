<template>
  <div v-if="show" class="page">
    <div
      :class="{
        shake: isShake,
      }"
      v-clickoutside="handler"
      class="content"
    >
      <div class="content_logo"></div>
      <div @click="showFalse" class="content_cross"></div>
      <div class="line1">
        <input
          v-model.number="form.userName"
          placeholder="输入手机号码"
          type="text"
          maxlength="11"
        />
      </div>
      <div class="line2">
        <div class="inputBox">
          <input
            v-model="form.registerCode"
            type="text"
            maxlength="6"
            placeholder="输入验证码"
          />
        </div>
        <div v-if="!countdown" @click="verificationCodeFn" class="countdown">
          发送验证码
        </div>
        <div v-if="countdown" class="countdown">{{ num }}s</div>
      </div>
      <div @click="login" class="btnBox">登录/注册</div>
    </div>
  </div>
</template>

<script>
import API from "@/api/index.js";
import clickoutside from "element-ui/src/utils/clickoutside";
export default {
  directives: { clickoutside },
  components: {},
  data() {
    return {
      show: false,
      num: 59,
      countdown: false,
      isShake: false,
      form: {
        userName: "",
        registerCode: "",
      },
    };
  },

  //计算属性
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  //方法
  methods: {
    showFn() {
      this.show = true;
    },
    showFalse() {
      this.show = false;
    },
    handler() {
      this.show = false;
    },
    verificationCodeFn() {
      //验证手机号码
      let reg =
        /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/;
      if (!reg.test(this.form.userName)) {
        this.$message.error("请输入正确的手机号码");
        this.isShake = true;
        setTimeout(() => {
          this.isShake = false;
        }, 500);
        return;
      }
      this.countdown = true;
      this.getCode();
      let timer = setInterval(() => {
        this.num--;
        if (this.num <= 0) {
          clearInterval(timer);
          this.countdown = false;
          this.num = 59;
        }
      }, 1000);
    },
    //获取验证码
    getCode() {
      let params = {
        userName: this.form.userName,
      };
      API.sendSms(params).then(res => {
        if (res.code == 200) {
          this.$message.success("验证码发送成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //注册登录
    login() {
      //判断是否有验证码
      if (!this.form.registerCode) {
        this.$message.error("请输入验证码");
        this.isShake = true;
        setTimeout(() => {
          this.isShake = false;
        }, 500);
        return;
      }
      API.login(this.form).then(res => {
        if (res.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
            duration: 1000,
          });
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$emit("afterLogin", true);
          this.show = false;
          //清除表单
          this.form.userName = "";
          this.form.registerCode = "";
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
//全屏遮罩
.page {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
}
.content {
  margin-top: 258px;
  width: 480px;
  height: 385px;
  background: #fff;
  border-radius: 24px;
  background-image: url("@/assets/reception/434343.png");
  background-size: 100% 100%;
  padding: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &_logo {
    width: 192px;
    height: 81px;
    background-image: url("@/assets/reception/79876.png");
    background-size: 100% 100%;
  }
  &_cross {
    width: 20px;
    height: 20px;
    background-image: url("@/assets/reception/54541.png");
    background-size: 14px 14px;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    top: 16px;
    right: 16px;
  }
  .line1 {
    margin-top: 36px;
    width: 380px;
    height: 48px;
    background: #f8e7c7;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    padding: 12px;
    // input去除边框颜色
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      background: none;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #7a2c00;
      line-height: 17px;
    }
  }
  .line2 {
    margin-top: 36px;
    width: 380px;
    height: 48px;
    background: #f8e7c7;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    padding: 4px 4px 4px 12px;
    // input去除边框颜色
    display: flex;
    .inputBox {
      flex: 1;
      input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        background: none;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #7a2c00;
        line-height: 17px;
      }
    }
    .countdown {
      margin-left: 10px;
      width: 102px;
      height: 40px;
      background: #551f00;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .btnBox {
    margin-top: 40px;
    width: 380px;
    height: 48px;
    background: #551f00;
    border-radius: 12px 12px 12px 12px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 19px;
  }
}
input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7a2c00;
  line-height: 17px;
}
.shake {
  //动画抖动
  animation: shake 0.5s;
  @keyframes shake {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5px, 0);
    }
    20% {
      transform: translate(5px, 0);
    }
    30% {
      transform: translate(-5px, 0);
    }
    40% {
      transform: translate(5px, 0);
    }
    50% {
      transform: translate(-5px, 0);
    }
    60% {
      transform: translate(5px, 0);
    }
    70% {
      transform: translate(-5px, 0);
    }
    80% {
      transform: translate(5px, 0);
    }
    90% {
      transform: translate(-5px, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }
}
</style>
