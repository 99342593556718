import service from "@/utils/request";

export default {
  // 首页轮播图
  bannerList: params => service.post("/banner/listAll", params),
  // 官方资讯列表
  informationOfficialList: params =>
    service.post("/informationOfficial/listToShow", params),
  // 官方资讯分组列表
  informationOfficialGroupList: params =>
    service.post("/informationOfficialGroup/listToShow", params),
  //资讯详情
  informationOfficialDetail: params =>
    service.post("/informationOfficial/getByIdToShow", params),
  //视频分组列表
  videoGroupList: params => service.post("/videoGroup/listToShow", params),
  // 视频列表
  videoList: params => service.post("/video/listToSHow", params),
};
// API.getRoutes().then((res) => {
//   let accessedRoutes;
//   const routes = res.data;
//   const asyncRoutes = getAsyncRoutes(routes.data); // 对路由格式进行处理
//   console.log(33, routes, asyncRoutes);
//   if (roles.includes("admin")) {
//     accessedRoutes = asyncRoutes || [];
//   } else {
//     // 这里是有做权限过滤的,如果不需要就不用
//     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
//   }
//   commit("SET_ROUTES", accessedRoutes);
//   resolve(accessedRoutes);
// });
