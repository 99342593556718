import service from "@/utils/request";

export default {
  // 验证码短信
  sendSms: params => service.post("/smsCode/sendLogin", params),
  // 官网手机账号登录,
  login: params => service.post("/login/OSLogin", params),
  //小程序信息修改
  updateUser: params => service.post("/user/mod", params),
};
// API.getRoutes().then((res) => {
//   let accessedRoutes;
//   const routes = res.data;
//   const asyncRoutes = getAsyncRoutes(routes.data); // 对路由格式进行处理
//   console.log(33, routes, asyncRoutes);
//   if (roles.includes("admin")) {
//     accessedRoutes = asyncRoutes || [];
//   } else {
//     // 这里是有做权限过滤的,如果不需要就不用
//     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
//   }
//   commit("SET_ROUTES", accessedRoutes);
//   resolve(accessedRoutes);
// });
