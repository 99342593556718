<template>
	<div>
		<!-- 内容 -->
		<div v-if="routerUrl != '/reception/homePage'" class="content">
			<div class="text">关于我们</div>
			<div class="iconBox">
				<a href="https://weibo.com/p/1006067753979188/home?from=page_100606&mod=TAB#place" class="icon1"></a>
				<a href="https://www.douyin.com/user/MS4wLjABAAAAMLg6E7GHMe8hXMIuC3FX_Z2TwsCa2N3Y8T3xmdoZ6a2L8yJ0HlM_2Dio0rtDm0bY"
					class="icon2"></a>
				<a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkzNzM3OTg1OA==#wechat_redirect"
					class="icon3"></a>
			</div>
			<div class="divisionLine"></div>
			<!-- 联系信息 -->
			<div class="contactInformation">
				<div class="contactInformation_left">
					<div class="text marginBottom32">联系我们</div>
					<div class="text opacity70 marginBottom12">电话：0591-</div>
					<div class="text opacity70">地址：福建省福州市台江区</div>
				</div>
				<div class="contactInformation_wechatQRCode"></div>
				<div style="display: flex; align-items: flex-end" class="text paddingBottom34">
					购买遇到问题？ 请扫左侧二维码迅速解决！
				</div>
				<div class="contactInformation_kaleLogoBox">
					<div class="contactInformation_kaleLogoBox_box"></div>
				</div>
			</div>
		</div>
		<div v-if="routerUrl == '/reception/homePage'" class="content1">
			<div class="text1">关于我们</div>
			<div class="iconBox">
				<a href="https://weibo.com/p/1006067753979188/home?from=page_100606&mod=TAB#place" class="icon11"></a>
				<a href="https://www.douyin.com/user/MS4wLjABAAAAMLg6E7GHMe8hXMIuC3FX_Z2TwsCa2N3Y8T3xmdoZ6a2L8yJ0HlM_2Dio0rtDm0bY"
					class="icon21"></a>
				<a href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzkzNzM3OTg1OA==#wechat_redirect"
					class="icon31"></a>
			</div>
			<div class="divisionLine1"></div>
			<!-- 联系信息 -->
			<div class="contactInformation">
				<div class="contactInformation_left">
					<div class="text1 marginBottom32">联系我们</div>
					<div class="text1 opacity70 marginBottom12">电话：0591-</div>
					<div class="text1 opacity70">地址：福建省福州市台江区</div>
				</div>
				<div class="contactInformation_wechatQRCode"></div>
				<div style="display: flex; align-items: flex-end" class="text1 paddingBottom34">
					购买遇到问题？ 请扫左侧二维码迅速解决！
				</div>
				<div class="contactInformation_kaleLogoBox">
					<div class="contactInformation_kaleLogoBox_box"></div>
				</div>
			</div>
			<!-- 备案号 -->
			<!-- <a href="https://beian.miit.gov.cn" style="display: flex; justify-content: center; text-decoration: none"
				class="text opacity20 paddingTop2">
				版权所有闽ICP备2023016110号-1
			</a> -->
		</div>
	</div>
</template>

<script>
	export default {
		components: {},
		data() {
			return {
				routerUrl: "",
			};
		},

		//计算属性
		computed: {},

		//监控data中的数据变化
		watch: {
			$route(to) {
				this.routerUrl = to.path;
			},
		},

		//生命周期 - 创建完成（可以访问当前this实例）
		created() {},
		//生命周期 - 挂载完成（可以访问DOM元素）
		mounted() {
			//获取当前路由地址
			this.routerUrl = this.$route.path;
		},
		//生命周期 - 创建之前
		beforeCreate() {},
		//生命周期 - 挂载之前
		beforeMount() {},
		//生命周期 - 更新之前
		beforeUpdate() {},
		//生命周期 - 更新之后
		updated() {},
		//生命周期 - 销毁之前
		beforeDestroy() {},
		//生命周期 - 销毁完成
		destroyed() {},
		//如果页面有keep-alive缓存功能，这个函数会触发
		activated() {},

		//方法
		methods: {},
	};
</script>
<style lang="scss" scoped>
	.content {
		width: 100%;
		height: 368px;
		background: #7a2c00;
		padding: 32px 210px;
		// background-image: url("@/assets/reception/2124.png");
		// background-size: 100% 100%;
		// margin-top: -25px;
	}

	.content1 {
		width: 100%;
		height: 368px;
		padding: 60px 210px;
		background-image: url("@/assets/reception/2124.png");
		background-size: 100% 100%;
		margin-top: -358px;
	}

	.iconBox {
		margin-top: 26px;
		width: 143px;
		height: 38px;
		display: flex;

		.icon1 {
			width: 35px;
			height: 34px;
			background-image: url("@/assets/reception/weibo.png");
			background-size: 100% 100%;
		}

		.icon11 {
			width: 38px;
			height: 38px;
			background-image: url("@/assets/reception/wb1.png");
			background-size: 100% 100%;
			margin-right: 8px;
		}

		.icon2 {
			width: 28px;
			height: 28px;
			background-image: url("@/assets/reception/douying.png");
			background-size: 100% 100%;
			margin-left: 22px;
		}

		.icon21 {
			width: 38px;
			height: 38px;
			background-image: url("@/assets/reception/dy1.png");
			background-size: 100% 100%;
			margin-right: 8px;
		}

		.icon3 {
			width: 38px;
			height: 38px;
			background-image: url("@/assets/reception/weixin.png");
			background-size: 100% 100%;
			margin-left: 20px;
		}

		.icon31 {
			width: 38px;
			height: 38px;
			background-image: url("@/assets/reception/wx1.png");
			background-size: 100% 100%;
		}
	}

	.divisionLine {
		margin: 34px 0px 36px;
		height: 1px;
		border: 1px solid #fff5e5;
	}

	.divisionLine1 {
		margin: 34px 0px 36px;
		height: 1px;
		border: 1px solid #674508;
	}

	.contactInformation {
		display: flex;
		position: relative;

		&_left {
			height: 100px;
			padding: 0px 4px;
			display: flex;
			flex-direction: column;
		}

		&_wechatQRCode {
			margin: 0px 32px 0px 20px;
			width: 100px;
			height: 100px;
			background-image: url("/src/assets/reception/wechatQRCode.png");
			background-size: 100% 100%;
		}

		&_kaleLogoBox {
			flex: 1;
			display: flex;
			justify-content: flex-end;

			&_box {
				width: 139px;
				height: 127px;
				background-image: url("/src/assets/reception/logo1.png");
				background-size: 100% 100%;
			}
		}
	}

	.text {
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: bold;
		color: #fff5e5;
		line-height: 16px;
	}

	.text1 {
		font-size: 16px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #674508;
		line-height: 22px;
	}
</style>