<template>
  <div>
    <div class="content">
      <img @click="show = !show" :src="userInfo.avatar" alt="" />
      <div
        :style="show ? 'transform: rotate(180deg)' : 'transform: rotate(0deg)'"
        @click="show = !show"
        class="icon"
      ></div>
      <div v-if="show" v-clickoutside="handler" class="details">
        <div class="details_top">
          <el-upload
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            action="https://j.kaletcg.com/kale/res/upFile"
            multiple
          >
            <div class="details_top_img">
              <img :src="userInfo.avatar" alt="" />
              <div class="details_top_prompt"></div>
            </div>
          </el-upload>
          <div
            :class="{
              modifyInformationClass: isModifyInformation == true,
            }"
            class="details_top_inputBox"
          >
            <div
              v-if="isModifyInformation == false"
              style="display: flex; justify-content: flex-start"
              class="details_top_inputBox_text"
            >
              {{ userInfo.nickName }}
            </div>
            <input
              v-if="isModifyInformation == true"
              maxlength="8"
              v-model="userInfo.nickName"
              class="details_top_inputBox_text"
              type="text"
            />
            <div
              v-if="isModifyInformation == false"
              @click="isModifyInformation = true"
              class="details_top_icon"
            ></div>
            <div
              v-if="isModifyInformation == true"
              @click="modifyInformation"
              class="details_top_icon1"
            ></div>
          </div>
        </div>
        <div @click="logOutOfLogin" class="details_bottom">
          <div class="details_bottom_icon"></div>
          <div>退出登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/index.js";
import clickoutside from "element-ui/src/utils/clickoutside";
export default {
  directives: { clickoutside },
  components: {},
  data() {
    return {
      show: false,
      //用户信息
      userInfo: {},
      isModifyInformation: false,
    };
  },

  //计算属性
  computed: {},

  //监控data中的数据变化
  watch: {},

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  //方法
  methods: {
    //退出登录
    logOutOfLogin() {
      localStorage.removeItem("userInfo");
      this.$emit("logOutOfLogin", false);
      //跳转首页
      this.$router.push("/");
    },
    // 修改信息
    modifyInformation() {
      let params = {
        nickName: this.userInfo.nickName,
        token: this.userInfo.token,
        avatar: this.userInfo.avatar,
      };
      API.updateUser(params).then(result => {
        localStorage.setItem("userInfo", JSON.stringify(result.data));
        this.isModifyInformation = false;
      });
    },
    handleAvatarSuccess(res) {
      this.userInfo.avatar = this.$imgUrl + res.data;
      this.modifyInformation();

      // this.userInfo.avatar = res.data;
      // this.modifyInformation();
    },
    handler() {
      this.show = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 94px;
  height: 40px;
  background: linear-gradient(360deg, #fdfaee 0%, #fff2e4 51%, #fff6d6 100%);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  border: 1px solid #7a2c00;
  padding: 5px 14px 5px 10px;
  align-items: center;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
  .icon {
    width: 16px;
    height: 16px;
    background-image: url("@/assets/reception/44334.png");
    background-size: 16px 16px;
    background-repeat: no-repeat;
  }
  .details {
    position: absolute;
    padding: 16px 0px 0px;
    top: 48px;
    right: 0px;
    width: 240px;
    height: 132px;
    background: linear-gradient(360deg, #fdfaee 0%, #fff2e4 51%, #fff6d6 100%);
    box-shadow: 0px 4px 8px 0px rgba(87, 44, 0, 0.2);
    border-radius: 12px;
    z-index: 100;
    &_top {
      display: flex;
      margin-bottom: 16px;
      padding: 0px 16px;
      align-items: center;
      &_img {
        width: 48px;
        height: 48px;
        //相对定位
        position: relative;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid #7a2c00;
        }
      }
      &_prompt {
        width: 48px;
        height: 48px;
        background-image: url("@/assets/reception/8928328.png");
        background-size: 48px 48px;
        background-repeat: no-repeat;
        position: absolute;
        top: 0px;
      }
      &_inputBox {
        flex: 1;
        margin-left: 12px;
        display: flex;
        align-items: center;
        &_text {
          width: 128px;
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #551f00;
          line-height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        // 去除默认边框默认背景
        input {
          outline: none;
          border: none;
          background: none;
        }
      }
      &_icon {
        width: 16px;
        height: 16px;
        background-image: url("@/assets/reception/44315.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
      &_icon1 {
        width: 16px;
        height: 16px;
        background-image: url("@/assets/reception/5437878.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
    &_bottom {
      //顶部边框
      border-top: 1px solid rgba(230, 214, 195, 1);
      width: 100%;
      height: 46px;
      // background: #551f00;
      padding: 12px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #551f00;
      line-height: 22px;
      &_icon {
        width: 16px;
        height: 16px;
        background-image: url("@/assets/reception/7655.png");
        background-size: 100% 100%;
        margin-right: 4px;
      }
    }
  }
}
.modifyInformationClass {
  padding-bottom: 8px;
  //底部边框
  border-bottom: 1px dashed rgba(230, 214, 195, 1);
}
</style>
