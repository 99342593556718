import Vue from "vue";
import VueRouter from "vue-router";
// 容器页
import reception from "@/views/reception/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/reception/homePage3",
  },
  {
    name: "reception",
    path: "/reception",
    component: reception,
    children: [
      //首页
      {
        path: "homePage",
        component: () => import("@/views/reception/homePage/index.vue"),
      },
      //仙剑3首页
      {
        path: "homePage3",
        component: () => import("@/views/reception/homePage3/index.vue"),
      },
      // 活动资讯
      {
        path: "moreInformation",
        component: () => import("@/views/reception/moreInformation/index.vue"),
      },
      // 活动资讯详情
      {
        path: "moreInformationDetails",
        component: () =>
          import("@/views/reception/moreInformation/details/index.vue"),
      },
      // 视频专区
      {
        path: "videoZone",
        component: () => import("@/views/reception/videoZone/index.vue"),
      },
      // 规则裁定
      {
        path: "ruleRuling",
        component: () => import("@/views/reception/ruleRuling/index.vue"),
      },
      //规则
      {
        path: "rulesOfEngagement",
        component: () =>
          import("@/views/reception/ruleRuling/rulesOfEngagement/index.vue"),
      },
      // 修订记录
      {
        path: "revisionRecord",
        component: () =>
          import("@/views/reception/ruleRuling/revisionRecord/index.vue"),
      },
      // 关于我们
      {
        path: "aboutUs",
        component: () => import("@/views/reception/aboutUs/index.vue"),
      },
      //卡牌图鉴
      {
        path: "cardGuide",
        component: () => import("@/views/reception/cardGuide/index.vue"),
      },
      // 赛事活动
      {
        path: "eventActivities",
        component: () => import("@/views/reception/eventActivities/index.vue"),
      },
      // 商品情报
      {
        path: "commodityInformation",
        component: () =>
          import("@/views/reception/commodityInformation/index.vue"),
      },
      // 构筑卡组
      {
        path: "deck",
        component: () => import("@/views/reception/deck/index.vue"),
      },
      // 新增卡组
      {
        path: "addDeck",
        component: () => import("@/views/reception/deck/addDeck/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};
// 路由守卫
router.beforeEach((to, from, next) => {
  //正则判断设备
  const isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(
    navigator.userAgent,
  );
  if (isMobile) {
    window.location.href = "http://m.kaletcg.com/";
  } else {
    next();
  }
});
export default router;
