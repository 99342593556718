//方法大集合
// 引入Base64;
import { Base64 } from "js-base64";
// createdAt年月日
function crAtSpecificDate(value) {
  let date = new Date(value);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  return `${year}-${month}-${day}`;
}
// createdAt年月日对象类型
function crAtSpecificDateOBJ(value) {
  let date = new Date(value);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let params = {
    year: year,
    month: month,
    day: day,
  };
  return params;
}
//输入值和保留的位数+...
function subStr(value, num) {
  if (value.length > num) {
    return value.substring(0, num) + "...";
  } else {
    return value;
  }
}
// Base64加密
function Base64Encode(value) {
  return Base64.encode(value);
}
// Base64解密
function Base64Decode(value) {
  return Base64.decode(value);
}
export default {
  crAtSpecificDate,
  crAtSpecificDateOBJ,
  subStr,
  Base64Encode,
  Base64Decode,
};
