<template>
  <div>
    <div class="content">
      <div
        v-if="
          currentRoute == '/reception/homePage' && $store.state.gameVersion == 4
        "
        class="logo"
      ></div>
      <div
        v-if="
          currentRoute != '/reception/homePage' && $store.state.gameVersion == 4
        "
        class="logo1"
      ></div>
      <div
        v-if="
          $store.state.gameVersion == 4 && currentRoute == '/reception/homePage'
        "
        class="tabs"
      >
        <div
          :class="{
            selected: index === tabsIndex,
          }"
          @click="selected(item, index)"
          v-for="(item, index) in tabs"
          :key="index"
          class="tabs_item"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="
          $store.state.gameVersion == 4 && currentRoute != '/reception/homePage'
        "
        class="tabs1"
      >
        <div
          :class="{
            selected1: index === tabsIndex,
          }"
          @click="selected(item, index)"
          v-for="(item, index) in tabs"
          :key="index"
          class="tabs1_item1"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="
          $store.state.gameVersion == 3 &&
          currentRoute == '/reception/homePage3'
        "
        class="tabs"
      >
        <div
          :class="{
            selected: index === tabsIndex,
          }"
          @click="selected(item, index)"
          v-for="(item, index) in tabs"
          :key="index"
          class="tabs_item"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        v-if="
          $store.state.gameVersion == 3 &&
          currentRoute != '/reception/homePage3'
        "
        class="tabs1"
      >
        <div
          :class="{
            selected1: index === tabsIndex,
          }"
          @click="selected(item, index)"
          v-for="(item, index) in tabs"
          :key="index"
          class="tabs1_item1"
        >
          {{ item.name }}
        </div>
      </div>
      <div
        :class="{
          switchFn1: currentRoute != '/reception/homePage',
        }"
        v-if="$store.state.gameVersion == 3"
        @click="switchFn(4)"
        class="switchFn"
      >
        <div
          v-if="currentRoute == '/reception/homePage3'"
          class="switchFn_icon"
        ></div>
        <div
          v-if="currentRoute != '/reception/homePage3'"
          class="switchFn_icon1"
        ></div>
        <div
          v-if="currentRoute == '/reception/homePage3'"
          class="switchFn_text"
        >
          卡乐仙剑四TCG首页
        </div>
        <div
          v-if="currentRoute != '/reception/homePage3'"
          class="switchFn_text1"
        >
          卡乐仙剑四TCG首页
        </div>
      </div>
      <div
        :class="{
          switchFn1: currentRoute != '/reception/homePage',
        }"
        v-if="$store.state.gameVersion == 4"
        @click="switchFn(3)"
        class="switchFn"
      >
        <div
          v-if="currentRoute == '/reception/homePage'"
          class="switchFn_icon"
        ></div>
        <div
          v-if="currentRoute != '/reception/homePage'"
          class="switchFn_icon1"
        ></div>
        <div v-if="currentRoute == '/reception/homePage'" class="switchFn_text">
          卡乐仙剑TCG首页
        </div>
        <div
          v-if="currentRoute != '/reception/homePage'"
          class="switchFn_text1"
        >
          卡乐仙剑TCG首页
        </div>
      </div>
      <div v-if="tabsIndex == 0" class="loginContainer">
        <div
          v-if="loginStatus == false"
          @click="showLogin"
          class="loginContainer_notLoggedIn"
        >
          登录/注册
        </div>
        <userInformation
          @logOutOfLogin="logOutOfLogin"
          v-if="loginStatus"
          class="marginLeft170"
        ></userInformation>
      </div>
      <div v-if="tabsIndex != 0" class="loginContainer1">
        <div
          v-if="loginStatus == false"
          @click="showLogin"
          class="loginContainer1_notLoggedIn"
        >
          登录/注册
        </div>
        <userInformation
          @logOutOfLogin="logOutOfLogin"
          v-if="loginStatus"
          class="marginLeft170"
        ></userInformation>
      </div>
    </div>
    <login @afterLogin="afterLogin" ref="login"></login>
  </div>
</template>

<script>
import login from "@/components/login/index.vue";
import userInformation from "@/components/userInformation/index.vue";
export default {
  components: { login, userInformation },
  data() {
    return {
      //tabs数据
      tabs: [
        {
          name: "首页",
          path: "/reception/homePage",
        },
        {
          name: "卡牌图鉴",
          path: "/reception/cardGuide",
        },
        {
          name: "商品情报",
          path: "/reception/commodityInformation",
        },
        {
          name: "构筑卡组",
          path: "/reception/deck",
        },
        { name: "赛事活动", path: "/reception/eventActivities" },
        { name: "规则/裁定", path: "/reception/ruleRuling" },
        { name: "关于我们", path: "/reception/aboutUs" },
      ],
      tabsIndex: 0,
      //登录状态
      loginStatus: false,
      // 当前路由
      currentRoute: "",
      // 版本号
      version: null,
    };
  },

  //计算属性
  computed: {},

  //监控data中的数据变化
  watch: {
    //监听路由变化
    $route() {
      //获取当前地址
      const path = this.$route.path;
      // 获取当前地址
      this.currentRoute = path;
      //判断path在tabs哪个里
      this.tabs.forEach((item, index) => {
        if (item.path == path) {
          this.tabsIndex = index;
        }
      });
    },
  },

  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    //获取当前地址
    const path = this.$route.path;
    // 当前路由
    this.currentRoute = path;
    if (this.currentRoute == "/reception/homePage") {
      this.$store.commit("setGameVersion", 4);
    }
    //判断path在tabs哪个里
    this.tabs.forEach((item, index) => {
      if (item.path == path) {
        this.tabsIndex = index;
      }
    });
    //查看缓存是否有登录
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo) {
      this.loginStatus = true;
    }
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  //方法
  methods: {
    //选中方法
    selected(item, index) {
      // 获取缓存
      const userInfo = localStorage.getItem("userInfo");
      if (!userInfo) {
        this.loginStatus = false;
      }
      if (this.loginStatus == false && index == 3) {
        this.$message({
          message: "请先登录",
          type: "warning",
          duration: 1000,
        });
        return;
      }
      //获取当前地址
      const path = this.$route.path;
      if (path == item.path) {
        return;
      }
      // if (this.$store.state.gameVersion == 3 && index == 3) {
      //   this.$message({
      //     message: "暂未开放",
      //     type: "warning",
      //     duration: 1000,
      //   });
      //   return;
      // }
      this.tabsIndex = index;
      if (this.$store.state.gameVersion == 3 && index == 0) {
        this.$router.push({
          path: "/reception/homePage3",
        });
        return;
      }
      //路由跳转
      this.$router.push({
        path: item.path,
      });
    },
    showLogin() {
      this.$refs.login.showFn();
    },
    afterLogin() {
      this.loginStatus = true;
    },
    logOutOfLogin() {
      this.loginStatus = false;
    },
    // 版本切换
    switchFn(version) {
      this.$store.commit("setGameVersion", version);
      // 判断路由
      if (
        this.$store.state.gameVersion == 4 &&
        this.currentRoute != "/reception/homePage"
      ) {
        this.$router.push({
          path: "/reception/homePage",
        });
        this.tabsIndex = 0;
      }
      if (
        this.$store.state.gameVersion == 3 &&
        this.currentRoute != "/reception/homePage3"
      ) {
        this.$router.push({
          path: "/reception/homePage3",
        });
        this.tabsIndex = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  width: 100%;
  height: 100px;
  // background-image: url("@/assets/reception/20230612111357.png");
  // background-size: 100% 100%;
  display: flex;
  align-items: center;
  // //相对定位
  // position: relative;
  padding-right: 54px;
  justify-content: flex-end;
}
.logo {
  width: 127px;
  height: 38px;
  background-image: url("@/assets/reception/8783222.png");
  background-size: 100% 100%;
  position: absolute;
  top: 32px;
  left: 40px;
}
.logo1 {
  width: 127px;
  height: 38px;
  background-image: url("@/assets/reception/4334.png");
  background-size: 100% 100%;
  position: absolute;
  top: 32px;
  left: 40px;
}
.tabs {
  width: 1094px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  &_item {
    cursor: pointer;
    width: 104px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: Source Han Serif CN-Medium, Source Han Serif CN;
    font-weight: 500;
    color: #9c8f98;
    line-height: 22px;
  }
  .selected {
    width: 104px;
    height: 20px;
    background-image: url("@/assets/reception/20230612111429.png");
    background-size: 104px 4px;
    background-repeat: no-repeat;
    background-position: center;
    //粗体
    font-weight: bold !important;
    font-size: 20px !important;
    font-family: Source Han Serif CN-Heavy, Source Han Serif CN;
    font-weight: 900 !important;
    color: #ffe181 !important;
    line-height: 22px !important;
  }
}
.tabs1 {
  width: 1094px;
  height: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 32px;
  &_item1 {
    cursor: pointer;
    width: 104px;
    font-size: 20px;
    font-family: Source Han Serif CN-Medium, Source Han Serif CN;
    font-weight: 500;
    color: #a28879;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .selected1 {
    width: 104px;
    height: 20px;
    background-image: url("@/assets/reception/655.png");
    background-size: 104px 4px;
    background-repeat: no-repeat;
    background-position: center;
    //粗体
    font-weight: bold !important;
    font-family: Source Han Serif CN-Heavy, Source Han Serif CN;
    font-weight: 900 !important;
    color: #551f00 !important;
    line-height: 22px !important;
  }
}
.loginContainer {
  display: flex;
  &_notLoggedIn {
    margin-left: 32px;
    width: 94px;
    height: 40px;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    border: 1px solid #ffe181;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffe181;
    line-height: 14px;
  }
}
.loginContainer1 {
  display: flex;
  &_notLoggedIn {
    margin-left: 32px;
    width: 94px;
    height: 40px;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    border: 1px solid #551f00;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #551f00;
    line-height: 14px;
  }
}
.switchFn {
  height: 40px;
  background: rgba(255, 249, 229, 0.15);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  padding: 8px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-left: 120px;
  &_icon {
    width: 24px;
    height: 24px;
    background-image: url("@/assets/reception/qh.png");
    background-size: cover;
  }
  &_icon1 {
    width: 24px;
    height: 24px;
    background-image: url("@/assets/reception/qh1.png");
    background-size: cover;
  }
  &_text {
    font-size: 20px;
    font-family: Source Han Serif CN, Source Han Serif CN;
    font-weight: 500;
    color: #ffe181;
    line-height: 20px;
  }
  &_text1 {
    font-size: 20px;
    font-family: Source Han Serif CN, Source Han Serif CN;
    font-weight: 500;
    color: #551f00;
    line-height: 20px;
  }
}
.switchFn1 {
  background: rgba(150, 55, 0, 0.1);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
}
</style>
