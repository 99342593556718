import service from "@/utils/request";

export default {
  // 图鉴列表
  illustratedList: params => service.post("/gameCard/listToShow", params),
  // 图鉴属性
  illustratedAttribute: params =>
    service.post("/cardAttribute/listToShow", params),
  // 卡牌系列列表,
  illustratedSeriesList: params =>
    service.post("/cardPackage/listToShow", params),
  // 罕见度
  illustratedRarity: params => service.post("/cardRate/listToShow", params),
  // 卡牌系列分类列表
  illustratedSeriesClassifyList: params =>
    service.post("/cardPackageGroup/listToShow", params),
  // 官网商品情报
  illustratedOfficialGoods: params =>
    service.post("/cardPackage/OSlistToShow", params),
  //卡牌类型
  illustratedCardType: params => service.post("/cardType/listToShow", params),
  // 图鉴属性列表
  illustratedAttributeList: params =>
    service.post("/cardAttribute/listToShow", params),
  // 罕见度列表
  illustratedRarityList: params => service.post("/cardRate/listToShow", params),
  //商品卡包列表
  illustratedGoodsList: params => service.post("/gameCard/threeList", params),
};
// API.getRoutes().then((res) => {
//   let accessedRoutes;
//   const routes = res.data;
//   const asyncRoutes = getAsyncRoutes(routes.data); // 对路由格式进行处理
//   console.log(33, routes, asyncRoutes);
//   if (roles.includes("admin")) {
//     accessedRoutes = asyncRoutes || [];
//   } else {
//     // 这里是有做权限过滤的,如果不需要就不用
//     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
//   }
//   commit("SET_ROUTES", accessedRoutes);
//   resolve(accessedRoutes);
// });
