var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content"},[(
        _vm.currentRoute == '/reception/homePage' && _vm.$store.state.gameVersion == 4
      )?_c('div',{staticClass:"logo"}):_vm._e(),(
        _vm.currentRoute != '/reception/homePage' && _vm.$store.state.gameVersion == 4
      )?_c('div',{staticClass:"logo1"}):_vm._e(),(
        _vm.$store.state.gameVersion == 4 && _vm.currentRoute == '/reception/homePage'
      )?_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"tabs_item",class:{
          selected: index === _vm.tabsIndex,
        },on:{"click":function($event){return _vm.selected(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(
        _vm.$store.state.gameVersion == 4 && _vm.currentRoute != '/reception/homePage'
      )?_c('div',{staticClass:"tabs1"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"tabs1_item1",class:{
          selected1: index === _vm.tabsIndex,
        },on:{"click":function($event){return _vm.selected(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(
        _vm.$store.state.gameVersion == 3 &&
        _vm.currentRoute == '/reception/homePage3'
      )?_c('div',{staticClass:"tabs"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"tabs_item",class:{
          selected: index === _vm.tabsIndex,
        },on:{"click":function($event){return _vm.selected(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(
        _vm.$store.state.gameVersion == 3 &&
        _vm.currentRoute != '/reception/homePage3'
      )?_c('div',{staticClass:"tabs1"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"tabs1_item1",class:{
          selected1: index === _vm.tabsIndex,
        },on:{"click":function($event){return _vm.selected(item, index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0):_vm._e(),(_vm.$store.state.gameVersion == 3)?_c('div',{staticClass:"switchFn",class:{
        switchFn1: _vm.currentRoute != '/reception/homePage',
      },on:{"click":function($event){return _vm.switchFn(4)}}},[(_vm.currentRoute == '/reception/homePage3')?_c('div',{staticClass:"switchFn_icon"}):_vm._e(),(_vm.currentRoute != '/reception/homePage3')?_c('div',{staticClass:"switchFn_icon1"}):_vm._e(),(_vm.currentRoute == '/reception/homePage3')?_c('div',{staticClass:"switchFn_text"},[_vm._v(" 卡乐仙剑四TCG首页 ")]):_vm._e(),(_vm.currentRoute != '/reception/homePage3')?_c('div',{staticClass:"switchFn_text1"},[_vm._v(" 卡乐仙剑四TCG首页 ")]):_vm._e()]):_vm._e(),(_vm.$store.state.gameVersion == 4)?_c('div',{staticClass:"switchFn",class:{
        switchFn1: _vm.currentRoute != '/reception/homePage',
      },on:{"click":function($event){return _vm.switchFn(3)}}},[(_vm.currentRoute == '/reception/homePage')?_c('div',{staticClass:"switchFn_icon"}):_vm._e(),(_vm.currentRoute != '/reception/homePage')?_c('div',{staticClass:"switchFn_icon1"}):_vm._e(),(_vm.currentRoute == '/reception/homePage')?_c('div',{staticClass:"switchFn_text"},[_vm._v(" 卡乐仙剑TCG首页 ")]):_vm._e(),(_vm.currentRoute != '/reception/homePage')?_c('div',{staticClass:"switchFn_text1"},[_vm._v(" 卡乐仙剑TCG首页 ")]):_vm._e()]):_vm._e(),(_vm.tabsIndex == 0)?_c('div',{staticClass:"loginContainer"},[(_vm.loginStatus == false)?_c('div',{staticClass:"loginContainer_notLoggedIn",on:{"click":_vm.showLogin}},[_vm._v(" 登录/注册 ")]):_vm._e(),(_vm.loginStatus)?_c('userInformation',{staticClass:"marginLeft170",on:{"logOutOfLogin":_vm.logOutOfLogin}}):_vm._e()],1):_vm._e(),(_vm.tabsIndex != 0)?_c('div',{staticClass:"loginContainer1"},[(_vm.loginStatus == false)?_c('div',{staticClass:"loginContainer1_notLoggedIn",on:{"click":_vm.showLogin}},[_vm._v(" 登录/注册 ")]):_vm._e(),(_vm.loginStatus)?_c('userInformation',{staticClass:"marginLeft170",on:{"logOutOfLogin":_vm.logOutOfLogin}}):_vm._e()],1):_vm._e()]),_c('login',{ref:"login",on:{"afterLogin":_vm.afterLogin}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }