import service from "@/utils/request";

export default {
  //公开卡组列表
  deckList: params => service.post("/app/deck/openDeckList", params),
  // 我的卡组
  myDeckList: params => service.post("/app/deck/myDeckList", params),
  //删除卡组
  deleteDeck: params => service.post("/app/deck/deleteDeck", params),
  // 新建卡组
  addDeck: params => service.post("/app/deck/addDeck", params),
  // 修改卡组
  updateDeck: params => service.post("/app/deck/updateDeck", params),
  // 卡组详情
  deckDetail: params => service.post("/app/deck/deckDetails", params),
  // 用户生成二维码
  userQrCode: params => service.post("/app/deck/qrcode", params),
};
// API.getRoutes().then((res) => {
//   let accessedRoutes;
//   const routes = res.data;
//   const asyncRoutes = getAsyncRoutes(routes.data); // 对路由格式进行处理
//   console.log(33, routes, asyncRoutes);
//   if (roles.includes("admin")) {
//     accessedRoutes = asyncRoutes || [];
//   } else {
//     // 这里是有做权限过滤的,如果不需要就不用
//     accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
//   }
//   commit("SET_ROUTES", accessedRoutes);
//   resolve(accessedRoutes);
// });
